import {
  CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import "@fontsource/inter";
import { theme, theme2 } from "./theme";
import { Navigate, Route, Routes } from "react-router-dom";
import { Principal } from "./pages/Principal";
import routes from "./routes";
import { Nav } from "./navigation/Nav";
import { Detalle } from "./pages/Detalle";
import { useEffect } from "react";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import { useDispatch, useSelector } from "react-redux";
import PrivateRoute, { PublicRoute } from "./rutas";
import { Carrito } from "./pages/Carrito";
import { Productos } from "./pages/Productos";
import { Nosotros } from "./pages/Nosotros";
import { Resultado } from "./pages/Resultado";

export default function App() {
  // const userAuth = useSelector((state) => state.userAuth);
  // const { userInfo } = userAuth;
  const dispatch = useDispatch();

  // const carrito = useSelector((state) => state.carrito?.carro);

  useEffect(() => {
    // window.addEventListener("storage", (e) => {
    //   if (e.key === "carritoPublimed") {
    //     cargarCarro();
    //   }
    // });
    cargarCarro();

    // console.log(
    //   Base64.stringify(
    //     hmacSHA512(sha256("ejemplo"), process.env.REACT_APP_ENCODE)
    //   )
    // );
    // console.log(
    //   Base64.stringify(
    //     hmacSHA512(sha256("ejemplo"), process.env.REACT_APP_ENCODE)
    //   )
    // );
    // return () => {
    //   window.removeEventListener("storage", (e) => {});
    // };
  }, []);

  async function cargarCarro() {
    //econsole.log("cargarCarro")
    let car = localStorage.getItem("carritoPublimed");

    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let carro = car ? JSON.parse(car) : [];

    fetch(
      `https://portal.publimed.net/api/v1/CProducto?searchParams={"where": [{ "type": "equals", "attribute": "estado","value":"ACTIVO"},` +
        `{ "type": "in", "attribute": "id","value":` +
        ` [${
          carro
            ?.map((val) => {
              return '"' + val?.producto?.id + '"';
            })
            ?.toString() ?? ""
        }]}]}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        let lista = res?.list ?? [];

        let respuesta = [];
        for (const val of carro) {
          let prod = lista.filter((val2) => val2?.id === val?.producto?.id)[0];
          if (prod) {
            let precio = !isNaN(val?.eleccion)
              ? [prod?.precio1, prod?.precio2, prod?.precio3][val?.eleccion]
              : prod?.precio;

            precio = parseFloat(precio).toFixed(2);
            respuesta.push({
              ...val,
              producto: prod,
              precioFinal: precio,
              total: (parseFloat(precio) * val?.cantidad).toFixed(2),
            });
          }
        }
        localStorage.setItem("carritoPublimed", JSON.stringify(respuesta));
        dispatch({
          type: "CARRO_SUCCESS",
          payload: {
            carro: respuesta,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "CARRO_SUCCESS",
          payload: {
            carro: [],
          },
        });
        localStorage.clear();
      });
  }

  async function consultar(li) {}

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: theme2 }}>
      <JoyCssVarsProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <Nav>
          <Routes>
            <Route path="/" element={<Principal />} />
            <Route path="/carrito" element={<Carrito />} />
            <Route path="/tienda" element={<Productos />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/producto/:id" element={<Detalle />} />

            <Route path="/resultado/:id" element={<Resultado />} />

            {/* <Route element={<PrivateRoute isAuth={userInfo?.email} />}>
              <Route path="/oportunidades" element={<Oportunidades />} />
            </Route> */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Nav>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}
