import { Box, Button, Chip, Option, Select, Stack } from "@mui/joy";
import {
  Pagination,
  PaginationItem,
  Paper,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { centrado } from "../utils/Estilos";
import { theme2 } from "../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import tienda from "../assets/banner/tienda.jpg";
import { useNavigate } from "react-router-dom";
import { Cargando } from "../utils/Cargando";
import { useSelector } from "react-redux";

export const Productos = () => {
  const [productos, setProductos] = useState(null);

  const limitador = 10;

  const [pagina, setPagina] = useState(1);

  const [imagenes, setImagenes] = useState({});

  const [filtro, setFiltro] = useState({
    orden: "Nuevo",
    especialidad: "Todos",
  });

  const navigate = useNavigate();

  const [especialidades, setEspecialidades] = useState([]);

  useEffect(() => {
    cargar();
    cargar2();
  }, []);

  function cargar2() {
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://portal.publimed.net/api/v1/Metadata", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setEspecialidades(
          res?.entityDefs?.CProducto?.fields?.especialidades?.options ?? []
        );
      })
      .catch((error) => console.error(error));
  }

  async function cargarImagenes(productos) {
    let respuesta = {};

    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    for (const el of productos) {
      if (el?.fotoId) {
        await fetch(
          "https://portal.publimed.net/api/v1/Attachment/file/" + el?.fotoId,
          requestOptions
        )
          .then((response) => response.blob())
          .then((result) => {
            let copia = {};
            copia[el?.fotoId] = URL.createObjectURL(result);
            respuesta = {
              ...respuesta,
              ...copia,
            };
          })
          .catch((error) => console.error(error));
      }
    }
    setImagenes(respuesta);
  }

  function cargar() {
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://portal.publimed.net/api/v1/CProducto?searchParams={"where": [{ "type": "equals", "attribute": "estado","value":"ACTIVO"}]}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setProductos([
          ...res.list.map((val) => {
            let precios = [];
            if (!isNaN(val?.precio1) && val?.stock1 > 0) {
              precios.push(parseFloat(val?.precio1));
            }
            if (!isNaN(val?.precio2) && val?.stock2 > 0) {
              precios.push(parseFloat(val?.precio2));
            }
            if (!isNaN(val?.precio3) && val?.stock3 > 0) {
              precios.push(parseFloat(val?.precio3));
            }

            if (!isNaN(val?.precio) && val?.tipo === "SIN STOCK") {
              precios.push(parseFloat(val?.precio));
            }
            return {
              ...val,
              minimo: Math.min(...precios),
              maximo: Math.max(...precios),
            };
          }),
        ]);

        cargarImagenes(res.list);
      })
      .catch((error) => {
        console.error(error);
        setProductos([]);
      });
  }

  return (
    <Box sx={{ ...centrado, width: "100%", flexWrap: "wrap" }}>
      <Cargando open={!productos} />
      <Box
        sx={{
          backgroundImage: "url(" + tienda + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "400px",
          width: "100%",
        }}
      />
      {/* <Typography
        variant="h3"
        color="white"
        fontWeight={"bold"}
        align="center"
        sx={{ width: "100%", marginTop: "-300px" }}
      >
        Tienda
      </Typography> */}
      {productos?.length !== 0 && (
        <>
          <Box sx={{ width: "90%" }} py={2}>
            {/* <Typography variant="h5" fontWeight={"bold"} pb={2}>
          Filtros
        </Typography> */}
            <Stack direction={"row"}>
              <Typography fontWeight={"bold"} pt={0.5} px={1}>
                Ordenar:
              </Typography>
              <Select
                sx={{ width: "200px" }}
                value={filtro?.orden}
                onChange={(e, n) => {
                  setPagina(1);
                  setFiltro({ ...filtro, orden: n });
                }}
              >
                <Option value={"Nuevo"}>Nuevo</Option>
                <Option value={"Menor precio"}>Menor precio</Option>
                <Option value={"Mayor Precio"}>Mayor Precio</Option>
              </Select>
            </Stack>
            <br />
            <Stack direction={"row"}>
              <Typography fontWeight={"bold"} pt={0.5} px={1}>
                Especialidad:
              </Typography>
              <Select
                sx={{ width: "200px" }}
                value={filtro?.especialidad ?? null}
                onChange={(e, n) => {
                  setPagina(1);
                  setFiltro({
                    ...filtro,
                    especialidad: n,
                  });
                }}
              >
                <Option value={"Todos"}>Todos</Option>
                {especialidades?.map((val) => (
                  <Option value={val} key={val}>
                    {val}
                  </Option>
                ))}
              </Select>
            </Stack>
            <br />
            <Typography px={1}>
              Mostrando de{" "}
              <strong>
                {pagina * limitador - limitador + 1} -{" "}
                {Math.min(
                  ...[
                    pagina * limitador,
                    productos?.filter((val) =>
                      filtro?.especialidad !== "Todos"
                        ? val?.especialidades?.includes(filtro?.especialidad)
                        : true
                    )?.length,
                  ]
                )}
              </strong>{" "}
              de{" "}
              {
                productos?.filter((val) =>
                  filtro?.especialidad !== "Todos"
                    ? val?.especialidades?.includes(filtro?.especialidad)
                    : true
                )?.length
              }{" "}
              productos
            </Typography>
          </Box>

          <Box sx={{ width: "90%", ...centrado, flexWrap: "wrap" }}>
            {productos
              ?.filter((val) =>
                filtro?.especialidad !== "Todos"
                  ? val?.especialidades?.includes(filtro?.especialidad)
                  : true
              )
              ?.sort((a, b) => {
                if (filtro?.orden === "Nuevo") {
                  return new Date(b?.createdAt) - new Date(a?.createdAt);
                } else if (filtro?.orden === "Menor precio") {
                  return a?.minimo - b?.minimo;
                } else {
                  return b?.minimo - a?.minimo;
                }
              })
              .slice(pagina * limitador - limitador, pagina * limitador)
              .map((val) => (
                <Zoom in={val?.id} key={val?.id}>
                  <Box sx={{ padding: "10px", width: "250px" }}>
                    <Paper
                      elevation={2}
                      sx={{
                        ":hover": {
                          boxShadow: 20,
                        },
                        padding: "20px",
                        border: 1,
                        borderRadius: "25px",
                        borderColor: theme2.palette.primary.main,
                      }}
                    >
                      <Stack spacing={2}>
                        <Box
                          sx={{
                            height: "0px",
                            padding: "0px",
                            margin: "0px",
                            textAlign: "right",
                          }}
                        >
                          {val?.descuento === "SI" && (
                            <Chip
                              sx={{ marginBottom: "-40px", marginRight: "5px" }}
                              color="danger"
                              variant="solid"
                            >
                              {" "}
                              Oferta
                            </Chip>
                          )}
                        </Box>
                        <img
                          alt={"fotoProducto"}
                          src={
                            imagenes && imagenes[val?.fotoId]
                              ? imagenes[val?.fotoId]
                              : "https://w7.pngwing.com/pngs/998/203/png-transparent-black-and-white-no-to-camera-logo-video-on-demand-retail-website-simple-no-miscellaneous-television-text.png"
                          }
                          width={"100%"}
                        />
                        <Typography
                          fontWeight={"bold"}
                          sx={{
                            height: "50px",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            WebkitLineClamp: 2,
                            textOverflow: "ellipsis",
                          }}
                        >
                          {val?.name}
                        </Typography>
                        {val?.especialidades?.length > 0 && (
                          <Box>
                            <strong>Especialidades:</strong>
                            <Typography noWrap>
                              {val?.especialidades?.toString()}
                            </Typography>
                          </Box>
                        )}
                        <Box>
                          {val?.descuento === "SI" && (
                            <Chip
                              color="neutral"
                              sx={{ textDecoration: "line-through" }}
                            >
                              {"$ " +
                                parseFloat(val?.precioSinDescuento).toFixed(2)}
                            </Chip>
                          )}
                          {val?.tipo === "SIN STOCK" ? (
                            <Chip color="success">
                              {"$ " + parseFloat(val?.precio).toFixed(2)}
                            </Chip>
                          ) : (
                            <Chip color="success">
                              {"$ " + parseFloat(val?.minimo).toFixed(2)}
                              {val?.minimo !== val?.maximo && (
                                <>
                                  {" ~ $ " + parseFloat(val?.maximo).toFixed(2)}
                                </>
                              )}
                            </Chip>
                          )}
                        </Box>
                        <Button
                          onClick={() => {
                            navigate("/producto/" + val?.id);
                          }}
                          variant="solid"
                        >
                          <Typography
                            fontWeight={"bold"}
                            sx={{ textAlign: "center" }}
                          >
                            Ver más
                          </Typography>
                        </Button>
                      </Stack>
                    </Paper>
                  </Box>
                </Zoom>
              ))}
          </Box>

          <Box sx={{ width: "100%", ...centrado }} pb={2}>
            <Stack spacing={2} py={2}>
              <Pagination
                size="large"
                page={pagina}
                onChange={(e, v) => {
                  setPagina(v);
                }}
                count={Math.ceil(productos?.length / limitador)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};
