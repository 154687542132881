import { Box, Breadcrumbs, Button, Divider, Link } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { Cargando } from "../utils/Cargando";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { centrado } from "../utils/Estilos";
import { theme2 } from "../theme";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Swal from "sweetalert2";
import CheckIcon from "@mui/icons-material/Check";

function generarString() {
  let result = "";
  const abc = // "a b c d e f g h i j k l m n o p q r s t u v w x y z" +
    (
      " 0 1 2 3 4 5 6 7 8 9" +
      " A B C D E F G H I J K L M N O P Q R S T U V W X Y Z"
    ).split(" "); // Espacios para convertir cara letra a un elemento de un array
  for (let i = 0; i <= 8; i++) {
    let valor = Math.random(); //NOSONAR
    const random = Math.floor(valor * abc.length);
    result += abc[random];
  }
  return result;
}

export const Carrito = () => {
  const dispatch = useDispatch();
  const carrito = useSelector((state) => state.carrito?.carro);
  const navigate = useNavigate();

  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [objeto, setObjeto] = useState({});
  const [load, setLoad] = useState(false);

  function validarCorreoElectronico(correoElectronico) {
    return !!regex.test(correoElectronico.toLowerCase());
  }

  useEffect(() => {
    if (!(objeto?.ids?.length >= 0) && !load) {
      setObjeto({
        ...objeto,
        carrito: carrito,
      });
    }
  }, [carrito]);

  function getTotal() {
    return objeto?.carrito
      ? objeto?.carrito
          ?.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseFloat(parseFloat(currentValue?.total ?? 0).toFixed(2)),
            0
          )
          .toFixed(2)
      : "0.00";
  }

  function getTotalCompra() {
    return objeto?.ids
      ? objeto?.ids
          ?.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseFloat(parseFloat(currentValue?.subtotal ?? 0).toFixed(2)),
            0
          )
          .toFixed(2)
      : "0.00";
  }

  function getDescuento() {
    if (!objeto?.cuponId) {
      return "0.00";
    }

    let valor = parseFloat(objeto?.cuponId?.valor ?? 0);

    if (objeto?.cuponId?.tipo === "VALOR") {
      return valor.toFixed(2);
    }

    let total = parseFloat(getTotal());
    let porcentaje = valor / 100;

    return (total * porcentaje).toFixed(2);
  }

  function validarCupon() {
    setLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `https://portal.publimed.net/api/v1/CCupon?searchParams={"where": [{ "type": "equals", "attribute": "name","value":"${objeto?.cupon}"},{ "type": "equals", "attribute": "estado","value":"NUEVO"}]}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);

        let cup = res?.list[0];
        if (cup) {
          Swal.fire({
            icon: "success",
            text: "Cupon agregado",
          });
          setObjeto({
            ...objeto,
            cuponId: cup,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "El cupón no esta disponible",
          });
          setObjeto({
            ...objeto,
            cupon: null,
          });
        }
        setLoad(false);
      })
      .catch((error) => {
        console.error(error);
        setObjeto({
          ...objeto,
          cupon: null,
        });
        setLoad(false);
        Swal.fire({
          icon: "error",
          text: "No se ha podido consultar el cupón",
        });
      });
  }

  function cambiarCantidad(valor, index) {
    //econsole.log(valor, index);
    let listaN = objeto?.carrito.concat([]);
    if (listaN[index] && listaN[index].cantidad + valor > 0) {
      let cantidad2 = listaN[index].cantidad + valor;
      listaN[index] = {
        ...listaN[index],
        cantidad: cantidad2,
        total: (parseFloat(listaN[index].precioFinal) * cantidad2).toFixed(2),
      };
    } else {
      listaN = listaN.filter((val, ind) => ind !== index);
    }

    dispatch({
      type: "CARRO_SUCCESS",
      payload: {
        carro: listaN,
      },
    });
    localStorage.setItem("carritoPublimed", JSON.stringify(listaN));
  }

  async function crearCompra() {
    setLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);
    myHeaders.append("Content-Type", "application/json");
    let contenido = {
      name: objeto?.correo?.trim(),
      nombre: objeto?.nombre?.trim(),
      description: objeto?.ids
        ?.map((val) => {
          return val.id;
        })
        ?.toString(),
      cedula: objeto?.cedula?.trim(),
      celular: objeto?.celular?.trim(),
      correo: objeto?.correo?.trim(),
      profesion: objeto?.profesion?.trim(),
      ciudad: objeto?.ciudad?.trim(),
      direccion: objeto?.direccion?.trim(),
      universidad: objeto?.universidad?.trim(),
      lugar: objeto?.lugar?.trim(),
      orcid: objeto?.orcid?.trim(),
      clienteId: objeto?.usuario ?? null,
      cuponId: objeto?.cuponId?.id ?? null,
      carritosIds:
        objeto?.ids?.map((val) => {
          return val.id;
        }) ?? [],

      total: objeto?.ids
        ?.reduce(
          (accumulator, currentValue) =>
            accumulator +
            parseFloat(parseFloat(currentValue?.subtotal ?? 0).toFixed(2)),
          0
        )
        .toFixed(2),
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(contenido),
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch("https://publimed.net/API/verificar.php", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        if (res?.id) {

          const requestOptions2 = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };
          fetch(
            "https://portal.publimed.net/api/v1/CCompra/" + res?.id,
            requestOptions2
          )
            .then((response) => response.text())
            .then((result) => {
              let res2 = JSON.parse(result);
              comprar(
                res2?.id,
                parseFloat(res2?.total).toFixed(2).replace(".", "")
              );
              setLoad(false);
            })
            .catch((error) => {
              console.error(error);
              setLoad(false);
              Swal.fire({
                icon: "error",
                text: "No se ha podido registrar la compra, revise el carrito e intente nuevamente",
              });
              setObjeto({
                carrito: carrito,
              });
            });
        } else {
          setLoad(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido registrar la compra, revise el carrito e intente nuevamente",
          });
          setObjeto({
            carrito: carrito,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: "No se ha podido registrar la compra, revise el carrito e intente nuevamente",
        });
        setObjeto({
          carrito: carrito,
        });
        setLoad(false);
      });
  }

  function comprar(id, total) {
    //econsole.log(id);
    let body = JSON.stringify({
      amount: total,
      amountWithoutTax: total,
      clientTransactionID: id,
      responseUrl: "https://publimed.net/API/respuesta2.php",
      cancellationUrl: "https://publimed.net/API/respuesta2.php",
    });
    let headers2 = new Headers();
    headers2.append(
      "Authorization",
      "Bearer "+process.env.REACT_APP_PAYPHONE
    );
    headers2.append("Content-Type", "application/json");
    let options = {
      method: "POST",
      headers: headers2,
      body: body,
    };
    fetch("https://pay.payphonetodoesposible.com/api/button/Prepare", options)
      .then((res) => res.text())
      .then((res) => {
        //econsole.log(JSON.parse(res));
        window.location.href = JSON.parse(res).payWithCard;
      })
      .catch((error) => {
        setLoad(false);
        console.error(error);
        Swal.fire({
          icon: "error",
          text: error,
        });
      });
  }

  async function crearUsuario() {
    setLoad(true);
    let usuario = await getUsuario(objeto?.correo?.trim());
    //econsole.log(usuario);
    if (!usuario) {
      const myHeaders = new Headers();
      myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);
      myHeaders.append("Content-Type", "application/json");
      let contenido = {
        name: objeto?.correo?.trim(),
        password: generarString(),
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(contenido),
        headers: myHeaders,
        redirect: "follow",
      };

      await fetch("https://portal.publimed.net/api/v1/CCliente", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let res = JSON.parse(result);
          //econsole.log("usuario creado");
          usuario = res?.id ?? null;
        })
        .catch((error) => {
          console.error(error);
          usuario = null;
        });
    }
    if (usuario) {
      crearCarrito(usuario);
    } else {
      setLoad(false);
      Swal.fire({
        icon: "error",
        text: "No se ha creado el usuario, intente más tarde",
      });
      console.error("No usuario");
    }
  }

  async function crearCarrito(usuario) {
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);
    myHeaders.append("Content-Type", "application/json");

    let idsCarrito = [];

    for (const el of objeto.carrito) {
      let cantidad = 1;
      while (cantidad <= el.cantidad) {
        let contenido = {
          clienteId: usuario,
          name: el.producto.name,
          productoId: el.producto.id,
          especialidad: el?.especialidad,
          tema: el?.tema,
          tiempo: el?.tiempo,
          eleccion: el?.eleccion,
        };

        const requestOptions = {
          method: "POST",
          body: JSON.stringify(contenido),
          headers: myHeaders,
          redirect: "follow",
        };

        let respuesta = await fetch(
          "https://portal.publimed.net/api/v1/CCarrito",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            let res = JSON.parse(result);
            if (!res?.id) {
              console.error(res);
              return null;
            } else {
              idsCarrito.push(res?.id);
              cantidad += 1;
              //econsole.log("carrito creado");
              return res.id;
            }
          })
          .catch((error) => {
            console.error(error);
            return null;
          });

        if (!respuesta) {
          setLoad(false);
          let tipo = !isNaN(el?.eleccion)
            ? " Tipo: " +
              [el?.producto?.tipo1, el?.producto?.tipo2, el?.producto?.tipo3][
                el?.eleccion
              ]
            : "";
          Swal.fire({
            icon: "error",
            text:
              "No se ha podido agregar el producto '" +
              el.producto.name +
              tipo +
              "', fue eliminado de su carrito," +
              " revisar disponibilidad e intentar nuevamente",
          });
          let listaN =
            objeto?.carrito?.filter(
              (val) =>
                !(
                  val.producto?.id === el.producto?.id &&
                  val.eleccion === el.eleccion
                )
            ) ?? [];
          dispatch({
            type: "CARRO_SUCCESS",
            payload: {
              carro: listaN,
            },
          });
          localStorage.setItem("carritoPublimed", JSON.stringify(listaN));
          localStorage.setItem("carritoPro", JSON.stringify([]));
          return;
        }
      }
    }
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url =
      `https://portal.publimed.net/api/v1/CCarrito?searchParams={"where": [{ "type": "in", "attribute": "id","value":` +
      ` [${
        idsCarrito
          ?.map((val) => {
            return '"' + val + '"';
          })
          ?.toString() ?? ""
      }]}]}`;
    let nuevosCarros = await fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        //econsole.log(res);
        return res?.list ?? null;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });

    if (nuevosCarros === null) {
      Swal.fire({
        icon: "error",
        text: "Ha ocurrido un error, revise el carrito e intente nuevamente",
      });
    } else {
      localStorage.setItem("carritoPro", JSON.stringify(nuevosCarros));
      //econsole.log("ids ", nuevosCarros);
      setObjeto({ ...objeto, ids: nuevosCarros, usuario: usuario });
    }
    setLoad(false);
  }

  async function getUsuario(usuario) {
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return await fetch(
      `https://portal.publimed.net/api/v1/CCliente?searchParams={"where": [{ "type": "equals", "attribute": "name","value": "${usuario}"}]}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        //econsole.log(res);
        //econsole.log("usuario obtenido");
        return res?.list ? res?.list[0]?.id : null;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  return (
    <Box py={3} sx={{ width: "100%", ...centrado, flexWrap: "wrap" }}>
      <Cargando open={!carrito} />
      <Cargando open={load} />
      {carrito && (
        <>
          <Box sx={{ width: "90%" }}>
            <Breadcrumbs sx={{ fontSize: "24px" }}>
              <Link
                underline="always"
                color="primary"
                onClick={() => navigate("/tienda")}
              >
                <strong>Tienda</strong>
              </Link>
              <Typography sx={{ color: "text.primary", fontSize: "24px" }}>
                <strong>Carrito</strong>
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box sx={{ width: "90%" }}>
            <Grid2 container sx={{ width: "100%", ...centrado }}>
              <Grid2
                size={{ xs: 12, md: 8 }}
                sx={{ padding: "20px", minWidth: 400, overflowX: "auto" }}
              >
                <TableContainer
                  component={Paper}
                  elevation={6}
                  sx={{ maxHeight: 400 }}
                >
                  <Table
                    stickyHeader
                    sx={{
                      borderCollapse: "separate",
                      borderSpacing: "0px 5px",
                      "& .MuiTableCell-stickyHeader": {
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "white",
                        backgroundColor: theme2.palette.primary.main,
                      },
                      "& .MuiTableCell-body ": {
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 200 }}>Producto</TableCell>
                        <TableCell align="rigth" sx={{ minWidth: 150 }}>
                          Precio
                        </TableCell>
                        <TableCell align="rigth" sx={{ minWidth: 150 }}>
                          Cantidad
                        </TableCell>
                        <TableCell align="rigth" sx={{ minWidth: 150 }}>
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {objeto?.carrito?.map((val, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <strong>{val?.producto?.name} </strong>
                            <Typography>
                              {!isNaN(val?.eleccion)
                                ? "TIPO: " +
                                  [
                                    val?.producto?.tipo1,
                                    val?.producto?.tipo2,
                                    val?.producto?.tipo3,
                                  ][val?.eleccion]
                                : ""}
                            </Typography>
                            {val?.producto?.solicitar?.map((val2) => (
                              <Typography key={val2}>
                                {val[val2.toLowerCase().split(" ")[0]]
                                  ? val2 +
                                    ": " +
                                    val[val2.toLowerCase().split(" ")[0]]
                                  : ""}
                              </Typography>
                            ))}
                          </TableCell>
                          <TableCell>$ {val?.precioFinal}</TableCell>
                          <TableCell>
                            {val?.producto?.tipo === "SIN STOCK" ? (
                              <Stack
                                direction={"row"}
                                sx={{ ...centrado, justifyContent: "left" }}
                              >
                                <IconButton
                                  onClick={() => {
                                    cambiarCantidad(-1, index);
                                  }}
                                >
                                  <RemoveIcon color="error" />
                                </IconButton>
                                <Typography>{val?.cantidad}</Typography>
                                <IconButton
                                  onClick={() => {
                                    cambiarCantidad(1, index);
                                  }}
                                >
                                  <AddIcon color="info" />
                                </IconButton>
                              </Stack>
                            ) : (
                              <Stack
                                direction={"row"}
                                sx={{ ...centrado, justifyContent: "left" }}
                              >
                                <IconButton
                                  onClick={() => {
                                    cambiarCantidad(-1, index);
                                  }}
                                >
                                  <RemoveIcon color="error" />
                                </IconButton>
                                <Typography>{val?.cantidad}</Typography>
                              </Stack>
                            )}
                          </TableCell>
                          <TableCell> $ {val?.total}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }} sx={{ padding: "20px" }}>
                <Paper
                  elevation={6}
                  sx={{
                    padding: "30px",
                    ...centrado,
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography pt={1}>
                      <strong>Correo electrónico: *</strong>
                    </Typography>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      autoComplete="email"
                      value={objeto?.correo ?? ""}
                      onChange={(e) =>
                        setObjeto({ ...objeto, correo: e.target.value })
                      }
                      size="small"
                    />
                    <Typography pt={3}>
                      <strong>Código Cupón: </strong>
                    </Typography>
                    <Stack direction={"row"} gap={1}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        disabled={objeto?.cuponId}
                        value={objeto?.cupon ?? ""}
                        onChange={(e) =>
                          setObjeto({
                            ...objeto,
                            cupon: e.target.value
                              ? e.target.value?.trim()
                              : e.target.value,
                          })
                        }
                        size="small"
                      />
                      <Button
                        variant="solid"
                        color="success"
                        disabled={
                          !(objeto?.cupon?.length > 0) || objeto?.cuponId
                        }
                        onClick={() => validarCupon()}
                      >
                        <CheckIcon />
                      </Button>
                    </Stack>
                    <Typography
                      align="right"
                      py={1}
                      sx={{ color: "text.primary", fontSize: "20px" }}
                    >
                      <strong>Subtotal: </strong>${getTotal()}
                    </Typography>
                    <Typography
                      align="right"
                      py={1}
                      sx={{ color: "text.primary", fontSize: "20px" }}
                    >
                      <strong>Descuento: </strong>${getDescuento()}
                    </Typography>
                    <Divider />
                    <Typography
                      py={1}
                      align="right"
                      sx={{ color: "text.primary", fontSize: "24px" }}
                    >
                      <strong>Total: </strong>$
                      {(
                        parseFloat(getTotal()) - parseFloat(getDescuento())
                      ).toFixed(2)}
                    </Typography>
                    <Button
                      size="lg"
                      onClick={() => crearUsuario()}
                      disabled={
                        (objeto?.cupon?.length > 0 && !objeto?.cuponId) ||
                        !validarCorreoElectronico(objeto?.correo ?? "") ||
                        !(objeto?.carrito?.length > 0)
                      }
                    >
                      Continuar con la compra
                    </Button>
                  </Box>
                </Paper>
              </Grid2>
            </Grid2>
          </Box>

          <Dialog
            open={objeto?.ids?.length > 0}
            maxWidth="md"
            fullWidth
            onClose={() => {
              setObjeto({
                carrito: carrito,
              });
            }}
          >
            <DialogContent>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  <strong>Datos Personales</strong>
                </Typography>

                <br />
                <Grid2 container spacing={2}>
                  <Grid2 item size={{ xs: 12, md: 6 }}>
                    <Typography>
                      <strong>Nombre Completo: *</strong>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      value={objeto?.nombre ?? ""}
                      onChange={(e) =>
                        setObjeto({ ...objeto, nombre: e.target.value })
                      }
                      size="small"
                    />
                  </Grid2>
                  <Grid2 item size={{ xs: 12, md: 6 }}>
                    <Typography>
                      <strong>Correo: *</strong>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      disabled
                      value={objeto?.correo ?? ""}
                      size="small"
                    />
                  </Grid2>
                  <Grid2 item size={{ xs: 12, md: 6 }}>
                    <Typography>
                      <strong>Cédula: *</strong>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      value={objeto?.cedula ?? ""}
                      onChange={(e) =>
                        setObjeto({ ...objeto, cedula: e.target.value })
                      }
                      size="small"
                    />
                  </Grid2>
                  <Grid2 item size={{ xs: 12, md: 6 }}>
                    <Typography>
                      <strong>Celular: *</strong>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      value={objeto?.celular ?? ""}
                      onChange={(e) =>
                        setObjeto({ ...objeto, celular: e.target.value })
                      }
                      size="small"
                    />
                  </Grid2>
                  <Grid2 item size={{ xs: 12, md: 6 }}>
                    <Typography>
                      <strong>Ciudad: *</strong>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      value={objeto?.ciudad ?? ""}
                      onChange={(e) =>
                        setObjeto({ ...objeto, ciudad: e.target.value })
                      }
                      size="small"
                    />
                  </Grid2>
                  <Grid2 item size={{ xs: 12, md: 6 }}>
                    <Typography>
                      <strong>Profesión: *</strong>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      value={objeto?.profesion ?? ""}
                      onChange={(e) =>
                        setObjeto({ ...objeto, profesion: e.target.value })
                      }
                      size="small"
                    />
                  </Grid2>
                  {objeto?.ids?.filter((val) => !isNaN(val?.eleccion)).length >
                    0 && (
                    <>
                      <Grid2 item size={{ xs: 12, md: 6 }}>
                        <Typography>
                          <strong>Dirección de lugar de entrega: *</strong>
                        </Typography>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          value={objeto?.direccion ?? ""}
                          onChange={(e) =>
                            setObjeto({ ...objeto, direccion: e.target.value })
                          }
                          size="small"
                        />
                      </Grid2>
                      <Grid2 item size={{ xs: 12, md: 6 }}>
                        <Typography>
                          <strong>Lugar de Trabajo: *</strong>
                        </Typography>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          value={objeto?.lugar ?? ""}
                          onChange={(e) =>
                            setObjeto({ ...objeto, lugar: e.target.value })
                          }
                          size="small"
                        />
                      </Grid2>
                      <Grid2 item size={{ xs: 12, md: 6 }}>
                        <Typography>
                          <strong>Universidad de Graduación: *</strong>
                        </Typography>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          value={objeto?.universidad ?? ""}
                          onChange={(e) =>
                            setObjeto({
                              ...objeto,
                              universidad: e.target.value,
                            })
                          }
                          size="small"
                        />
                      </Grid2>
                      <Grid2 item size={{ xs: 12, md: 6 }}>
                        <Typography>
                          <strong>ORCID: </strong>
                          {"(opcional) "}
                          <a
                            href="https://orcid.org/register"
                            target="_blank"
                            rel="noreferrer"
                          >
                            orcid.org/register
                          </a>
                        </Typography>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          value={objeto?.orcid ?? ""}
                          onChange={(e) =>
                            setObjeto({ ...objeto, orcid: e.target.value })
                          }
                          size="small"
                        />
                      </Grid2>
                    </>
                  )}
                </Grid2>
              </Box>
            </DialogContent>
            <DialogActions>
              <Typography
                pr={2}
                sx={{ color: "text.primary", fontSize: "24px" }}
              >
                <strong>Total: </strong>$
                {(
                  parseFloat(getTotalCompra()) - parseFloat(getDescuento())
                ).toFixed(2)}
              </Typography>
              <Button
                color="success"
                sx={{ margin: "5px" }}
                onClick={() => crearCompra()}
                // disabled={
                //   !(objeto?.nombre && objeto?.nombre?.trim()?.length > 0) ||
                //   !(objeto?.cedula && objeto?.cedula?.trim()?.length > 0) ||
                //   !(objeto?.celular && objeto?.celular?.trim()?.length > 0) ||
                //   !(objeto?.correo && objeto?.correo?.trim()?.length > 0) ||
                //   !(
                //     objeto?.profesion && objeto?.profesion?.trim()?.length > 0
                //   ) ||
                //   !(objeto?.ciudad && objeto?.ciudad?.trim()?.length > 0) ||
                //   !(
                //     objeto?.ids?.filter((val) => !isNaN(val?.eleccion)).length >
                //       0 &&
                //     objeto?.direccion &&
                //     objeto?.direccion?.trim()?.length > 0
                //   ) ||
                //   !(
                //     objeto?.ids?.filter((val) => !isNaN(val?.eleccion)).length >
                //       0 &&
                //     objeto?.universidad &&
                //     objeto?.universidad?.trim()?.length > 0
                //   ) ||
                //   !(
                //     objeto?.ids?.filter((val) => !isNaN(val?.eleccion)).length >
                //       0 &&
                //     objeto?.lugar &&
                //     objeto?.lugar?.trim()?.length > 0
                //   )
                // }
              >
                PAGAR
              </Button>
              <Button
                color="danger"
                sx={{ margin: "5px" }}
                onClick={() =>
                  setObjeto({
                    carrito: carrito,
                  })
                }
              >
                CANCELAR
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};
