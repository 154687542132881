import { CircularProgress, Backdrop } from "@mui/material";

export const Cargando = ({ open = false }) => {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 200 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };
  