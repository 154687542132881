import Menu from "@mui/icons-material/Menu";
import { Badge, Box, Button, IconButton } from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import {
  Collapse,
  Dialog,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "./../assets/image/LOGO 4.3.png";
import { Link, useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";

export const Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const carrito = useSelector((state) => state.carrito?.carro ?? []);

  // useEffect(() => {
  //   updateBadgeCount();
  //   const interval = setInterval(updateBadgeCount, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  // function updateBadgeCount() {
  //   let car = localStorage.getItem("carritoPublimed");
  //   setCarrito(car ? JSON.parse(car).length : 0);
  // }

  return (
    <Paper elevation={10}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{
          width: "96%",
          margin: "0% 2% 0% 2%",
          // background: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Box padding={"10px"}>
          <Button
            variant="plain"
            size="lg"
            onClick={() => navigate("/")}
            component={Link}
            to={"/"}
          >
            <img alt="logo" src={logo} width={"100px"} />
          </Button>
        </Box>
        <Box padding={"10px"} display={{ xs: "none", sm: "flex" }}>
          <Button
            variant="plain"
            size="lg"
            onClick={() => navigate("/tienda")}
            component={Link}
            to={"/tienda"}
          >
            <Typography fontWeight={"bold"} variant="h6">
              Tienda
            </Typography>
          </Button>
          <Button
            variant="plain"
            size="lg"
            onClick={() => navigate("/carrito")}
            component={Link}
            to={"/carrito"}
          >
            <Typography fontWeight={"bold"} variant="h6" pr={1}>
              Carrito
            </Typography>
            <Badge
              badgeContent={carrito?.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  parseFloat(
                    parseFloat(currentValue?.cantidad ?? 0).toFixed(2)
                  ),
                0
              )}
              color="danger"
            >
              <ShoppingCartIcon />
            </Badge>
          </Button>
          <Button
            component={Link}
            to={"/nosotros"}
            variant="plain"
            size="lg"
            onClick={() => navigate("/nosotros")}
          >
            <Typography fontWeight={"bold"} variant="h6">
              Nosotros
            </Typography>
          </Button>
        </Box>
        <Box padding={"10px"} display={{ xs: "flex", sm: "none" }}>
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
          >
            <Menu />
          </IconButton>
        </Box>
      </Box>
      <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ width: "100%", textAlign: "right" }}>
              <IconButton
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Button
              variant="plain"
              size="lg"
              onClick={() => {
                setOpen(false);
                navigate("/tienda");
              }}
            >
              <Typography fontWeight={"bold"} variant="h6">
                Tienda
              </Typography>
            </Button>
            <br />
            <Button
              variant="plain"
              size="lg"
              onClick={() => {
                setOpen(false);
                navigate("/carrito");
              }}
            >
              <Typography fontWeight={"bold"} variant="h6">
                Carrito
              </Typography>
            </Button>
            <br />
            <Button
              variant="plain"
              size="lg"
              onClick={() => {
                setOpen(false);
                navigate("/nosotros");
              }}
            >
              <Typography fontWeight={"bold"} variant="h6">
                Nosotros
              </Typography>
            </Button>
            <br />
          </Collapse>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};
